<template>
<Prime-Page>
  <ion-content>
    <ion-grid class="margin-bottom-twenty full-width full-height">
      <ion-row class="full-height" v-if="isLoading">
        <div class="centerItems text-muted display-flex valign full-height full-width">
          <ion-spinner name="lines" class="spinner-large valign"></ion-spinner>
        </div>
      </ion-row>
      <ion-row v-else class="full-height">
        <ion-col>
          <ion-row class="ion-justify-content-center full-height">
            <ion-col size-sm="10" size-md="8" size-lg="6">
              <h3>Care Team</h3>
              <ion-card color="white" class="prime-patient-care-team-card">
                <ion-card-header v-if="defaultCareTeam">
                  <div v-if="defaultCareTeam.length > 0">
                    <h6 style="margin-top: 15px;">Care Team Members</h6>
                    <!-- {{defaultCareTeam}} -->
                    <ion-card v-for="(member, key) in defaultCareTeam" :key="key" class="prime-professional-default-card">
                      <ion-item lines="none" class="full-width">
                        <div slot="start" class="avatar" v-if="member.firstName && member.lastName">
                          {{ member.firstName.charAt(0) + member.lastName.charAt(0) }}
                        </div>
                        <div slot="start">
                          <div class="prime-professional-card-name">
                            {{ member.title | formatDisplayName(member.firstName, member.lastName, member.suffix) }}
                          </div>
                          <div class="prime-professional-card-info">
                            <span v-if="member.role">{{ member.role }} </span>
                          </div>
                        </div>
                        <div slot="end" class="cursor-pointer">
                          <ion-icon v-if="member.taskNotificationEnabled" @click="changeCareTeamMemberOptions(member, 'taskNotificationEnabled', false)" v-tooltip="'Disable notifications for this member'" class="icon-grey" name="md-notifications"></ion-icon>
                          <ion-icon v-else @click="changeCareTeamMemberOptions(member, 'taskNotificationEnabled', true)" v-tooltip="'Enable notifications for this member'" class="icon-grey" name="md-notifications-off"></ion-icon>
                        </div>
                        <div slot="end" class="close-button cursor-pointer margin-left-ten" @click="confirmChangeCareTeam(member, false)">
                          &times;
                        </div>
                      </ion-item>
                    </ion-card>
                  </div>
                  <div v-else>
                    <h5 class="text-muted text-center">
                      There are no Care Team Members to display
                    </h5>
                  </div>
                </ion-card-header>
                <!-- {{professionals}} -->
                <ion-card-content>
                  <div v-if="professionals">
                    <h6 style="margin-top: 0;">Add Care Team Members</h6>
                    <ion-card v-for="(professional, key) in availableProfessionals" :key="key" class="prime-professional-card cursor-pointer" @click="confirmChangeCareTeam(professional, true)">
                      <!-- <div class="checkbox">
                <input
                    :id="key"
                    type="checkbox"
                    color="primary"
                    v-model="professional.careTeamMember"
                    @change="confirmChangeCareTeam(professional)"
                />
                <label :for="key"></label>
            </div> -->
                      <div class="avatar" v-if="professional.firstName && professional.lastName">
                        {{ professional.firstName.charAt(0) + professional.lastName.charAt(0) }}
                      </div>
                      <div>
                        <div class="prime-professional-card-name">
                          {{ professional.title | formatDisplayName(professional.firstName, professional.lastName, professional.suffix) }}
                        </div>
                        <div class="prime-professional-card-info">
                          <span v-if="professional.role">{{ professional.role }} </span>
                        </div>
                      </div>
                    </ion-card>
                  </div>
                  <div v-else>
                    <h5 class="text-muted text-center">
                      There are no professionals available to add to a care team
                    </h5>
                  </div>
                </ion-card-content>
              </ion-card>
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-content>
</Prime-Page>
</template>

<script>
import { send as httpSend } from "@/services/Api";

import { addIcons } from "ionicons";
import { notifications, notificationsOff } from "ionicons/icons";
import PrimePage from "@/components/Global/PrimePage";

addIcons({
  "md-notifications": notifications.md,
  "md-notifications-off": notificationsOff.md
});
export default {
  name: "CareTeam",
  components: {
    PrimePage
  },
  data() {
    return {
      defaultCareTeam: undefined,
      professionals: undefined,
      isLoading: false
    };
  },
  mounted() {
    this.getProfessionals();
    this.getCareTeam(this.$route.params.id);
  },
  methods: {
    getProfessionals() {
      const method = "get";
      const path = document.config.professionals;
      this.isLoading = true;

      httpSend({ path, method, authToken: true })
        .then(result => {
          this.professionals = result.data;
          this.isLoading = false;
        })
        .catch(error => {
          this.$ionic.toastController
            .create({
              header: "Error",
              message: "There was a problem loading the page. Please try again later.",
              duration: 5000,
              position: "top"
            })
            .then(m => m.present());
          khanSolo.log(error);
          this.isLoading = false;
        });
    },
    getCareTeam(id) {
      const method = "get";
      const path = document.config.defaultCareTeam + id;
      // this.isLoading = true;

      httpSend({ path, method, authToken: true })
        .then(result => {
          this.defaultCareTeam = result.data;
        })
        .catch(error => {
          this.$ionic.toastController
            .create({
              header: "Error",
              message: "There was a problem loading the page. Please try again later.",
              duration: 5000,
              position: "top"
            })
            .then(m => m.present());
          khanSolo.log(error);
        });
    },
    confirmChangeCareTeam: function(member, willAdd) {
      const alertHeaderTxt = willAdd ? "Confirm Add" : "Confirm Removal";
      const alertmessageTxt = willAdd ? "Are you sure you want to add <strong>" + (member.lastName ? member.lastName : "") + (member.firstName ? ", " + member.firstName : "") + (member.suffix ? " " + member.suffix : "") + "</strong> to this care team?" : "Are you sure you want to remove <strong>" + (member.lastName ? member.lastName : "") + (member.firstName ? ", " + member.firstName : "") + (member.suffix ? " " + member.suffix : "") + "</strong> from this care team?";
      const confirmBtnTxt = willAdd ? "Add" : "Remove";
      return this.$ionic.alertController
        .create({
          header: alertHeaderTxt,
          message: alertmessageTxt,
          mode: "ios",
          buttons: [
            {
              text: "Cancel",
              role: "cancel",
              cssClass: "secondary",
              handler: () => {
                member.careTeamMember = !member.careTeamMember;
              }
            },
            {
              text: confirmBtnTxt,
              handler: () => {
                this.editTeamMember(member.id, willAdd ? "put" : "delete");
              }
            }
          ]
        })
        .then(a => a.present());
    },
    changeCareTeamMemberOptions: function(member, queryName, bool) {
      let param = "?" + queryName + "=" + bool;
      let toastMessage = "This care team member will " + (bool ? "now receive" : "no longer receive") + " notifications";

      this.editTeamMember(member.id, "put", param, toastMessage);
    },
    editTeamMember(id, addOrRemove, param = "", toastMessage = "User Settings have been updated") {
      //this.isLoading = true;
      const method = addOrRemove;
      const path = document.config.defaultCareTeam + this.$route.params.id + "/" + id + param;

      httpSend({ path, method, authToken: true })
        .then(() => {
          this.$ionic.toastController
            .create({
              header: "Care Team Updated",
              message: toastMessage,
              duration: 2000,
              color: "primary",
              mode: "ios"
            })
            .then(m => m.present(), this.getCareTeam(this.$route.params.id));
          this.loading = false;
        })
        .catch(error => {
          this.$ionic.toastController
            .create({
              header: "Error",
              message: "Could not save Patient Settings. Please try again later.",
              duration: 5000,
              position: "top"
            })
            .then(m => m.present());

          khanSolo.log(error);
          this.loading = false;
        });
    }
  },
  computed: {
    currentProfessional() {
      return this.$store.getters["currentProfessional/get"];
    },
    availableProfessionals: function() {
      const t = this;
      return this.professionals.filter(({ id }) => t.defaultCareTeam && !t.defaultCareTeam.some(x => x.id == id));
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.prime-patient-care-team-card {
  padding: 0;
}
.prime-patient-care-team-card ion-card-header ion-select {
  margin-bottom: 0;
}
.prime-patient-care-team-card ion-card-content {
  background: var(--ion-modal-secondary-background);
  border-top: 2px solid var(--ion-color-light-shade);
  padding-top: 20px;
  max-height: 400px;
  overflow: auto;
}

.close-button {
  font-size: 30px;
  border-radius: 20px;
  color: #a0a0a0;
  padding: 1px;
}

.action-icon-add {
  color: #0f5db8;
  font-size: 35px;
}
ion-card {
  margin: 0px !important;
  padding: 10px;
}
ion-card.prime-professional-card {
  background: #ffffff;
  color: var(--ion-text-color);
  display: flex;
  align-items: center;
  margin: 0 0 10px 0 !important;
  box-shadow: 0 1px 6px 0px rgba(46, 61, 73, 0.2) !important;
  transition: all 0.2s ease-in-out;
}
ion-card.prime-professional-card:hover {
  box-shadow: 0 3px 15px 0px rgba(46, 61, 73, 0.3) !important;
}
ion-card.prime-professional-card .checkbox {
  margin-left: 5px;
}
ion-card.prime-professional-card .avatar {
  width: 50px;
  height: 50px;
  font-size: 18px;
  margin-left: 10px;
  margin-right: 20px;
}
ion-card.prime-professional-card .prime-professional-card-name ion-card.prime-professional-default-card .prime-professional-card-name {
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
ion-card.prime-professional-card .prime-professional-card-info ion-card.prime-professional-default-card .prime-professional-card-info {
  font-size: 12px;
  font-weight: 500;
  margin-top: 2px;
  color: var(--ion-color-medium-shade);
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
ion-card.prime-professional-default-card {
  background: var(--ion-modal-secondary-background);
  color: var(--ion-text-color);
  display: flex;
  align-items: center;
  margin: 0 0 10px 0 !important;
  box-shadow: 0 1px 6px 0px rgba(46, 61, 73, 0.2) !important;
  transition: all 0.2s ease-in-out;
}
ion-card .avatar {
  width: 50px;
  height: 50px;
  font-size: 18px;
  margin-left: 10px;
  margin-right: 20px;
}
/* ion-card.prime-patient-card .prime-patient-card-icons img {
  width: 5px;
  margin: 0 0 0 10px;
  color: #dadada;
} */

.pcp-icon {
  width: 25px;
}
.pcp-icon.active {
  width: 35px;
}
</style>
